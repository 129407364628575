
// ============================================================
//  @Colors
// ============================================================

$brand_primary                  :  #0082aa;
$brand_success                  :  #137E00;
$brand_info                     :  #4A90B9;
$brand_warning                  :  #FF7F00;
$brand_error                    :  #d9534f;
$brand_disable                  :  #888888;

// ------------------------------------------------------------
// brand color
// ------------------------------------------------------------

$brand_main                     :  #333;

$brand_main-lighter             :  $brand_main;
$brand_main_lighten             :  $brand_main;

$brand_main_darker              :  $brand_main;
$brand_main_darken              :  $brand_main;

$brand_theme01                  :  #00C4D5;

$brand_gray_lighter             :  #F8F8F8;
$brand_gray_lighten             :  $brand_gray_lighter;
$brand_gray_darker              :  $brand_main;
$brand_gray_darken              :  $brand_main;

// ------------------------------------------------------------
// parts color
// ------------------------------------------------------------

$background_layer_color         : rgba(0, 0, 0, 0.7);


// ------------------------------------------------------------
// text color
// ------------------------------------------------------------
$text_color                     : #3b3b3b;
$text_color_sub                 : #888888;
$text_color_sub_lighter         : #aaaaaa;
$text_color_sub_lighten         : #d6d6d6;
$text_color_placeholder         :  #BCBCBC;
$text_color_disable             :  $brand_disable;
$text_color_white               :  #fff;
$border_color_base              :  #707070;
$border_color_base_lighter       :  #cccccc;

// ------------------------------------------------------------
// link color
// ------------------------------------------------------------
//$link_color                     :  #0079D1;
$link_color                     :  $brand_theme01;
$link_visited_color             :  $link_color;
$link_active_color              :  $link_color;
$link_hover_color               :  $link_color;

// ============================================================
// @ タイポグラフィ設定
// ============================================================

$font_family_base               :  "Hiragino Kaku Gothic ProN",  "Hiragino Sans","Helvetica Neue", "Roboto", Arial,"游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
$font_family_brand_type         :  'Apercu Pro', sans-serif;
$font_family_mincho             :  'Shippori Mincho B1', serif , sans-serif;
$font_size_base_SP              :  14;
$font_size_base_PC              :  16;
$headings_color                 :  #333;
$line_height_base               :  1.8;
$line_height_heading            :  1.4em;

// ============================================================ //
// @ border-radius
// ============================================================ //

$border-radius-base             : 5px;

// ============================================================ //
// @ input関係
// ============================================================ //

$input_bg                       :  #fff;
$input_hover_bg                 :  #f4f4f4;
$input_bg_disabled              :  $brand_gray_lighten;
$input_pad                      :  .4em .6em .4em;
$input_pad-sm                   :  .8em .6em;
$input_color                    :  $text_color;
$input_border                   :  #ccc;
$input_border_radius            :  $border-radius-base;
$input_active_color             :  $brand_primary;

// ============================================================ //
// @ transition & hover opacity 設定
// ============================================================ //

$hover_transition               :  .3s;
$hover_opacity                  :  .7;

// ============================================================
// @ 全体構造定義
// ============================================================
$container_pad_PC               : 3rem;
$container_pad_SP               : 1.5rem;
$header_H_SP                    : 55px;
$header_H_TAB                   : 60px;
$header_H_PC                    : 70px;
$BRAND_DECORATION_OFFSET_SP     : 70px;


// ============================================================ //
// @ Z-index定義
// ============================================================ //
$loading-layer                  : 99999;
$zindex_modal                   : 30000;
$zindex_modal_bg                : $zindex_modal - 1;
$zindex_offcanvas_trigger       : 7999;
$zindex_offcanvas_menu          : $zindex_offcanvas_trigger - 1;
$zindex_offcanvas_bg            : $zindex_offcanvas_menu - 1;
$zindex_globalHeader            : 5000;
$header_H_PC                     : 1000;
$zindex_top_contentsContainer   : 9999;
$zindex_mv                      : 500;


// ============================================================
// フォーム関係
// ============================================================

$field_label_mb                 : 0.75rem;
$field_border_color             : #dee2e6;
$field_border_radius            : 5px;
$field_padding_vertical         : 0.75rem;
$field_padding_side             : 0.75rem;
$field_line_height              : 1.4rem;
$field_focus_border_color       : #86b7fe;
$field_focus_outline_color      : rgba(13,110,253,.25);
$field_disabled_bg_color        : #e9ecef;

// ============================================================
// グリッドサイズ（_mixin.scssで使用）
// ============================================================

$grid-sizes: (
  1: 100px,
  2: 150px,
  3: 200px,
  4: 250px,
  5: 300px,
) !default;


// ============================================================ //
// @ メディアクエリ設定
// ============================================================ //

// Extra small screen / phoneSE
$screen_xs                      :  320px;
$screen_xs_min                  :  $screen_xs;

// Small Mid Small screen / Iphone
$screen_sm                      :  430px;
$screen_msm                     :  375px;
$screen_msm_min                 :  $screen_sm;

// Small screen / Iphone Plus

$screen_sm_min                  :  $screen_sm;

// iphone5 land
$screen_5ls                     :  568px;

// iphone6 land
$screen_6ls                     :  667px;

// Medium screen
$screen_md                      :  769px;
$screen_md_min                  :  $screen_sm + 1;
$screen_desktop                 :  $screen_md_min;

// Small large screen
$screen_slg                     :  980px;
$screen_slg_min                 :  $screen_md + 1;

// Mid large screen
$screen_mlg                     :  1024px;
$screen_mlg_min                 :  $screen_slg + 1;

// Large screen
$screen_lg                      :   1580px;
$screen_lg-min                  :   $screen_md + 1;

// Xlarge screen
$screen_xlg                     :   1980px;
$screen_xlg_min                 :   $screen_lg + 1;


$blakepoints: (
  'xlg'                         : $screen_xlg,
  'lg'                          : $screen_lg,
  'mlg'                         : $screen_mlg,
  'slg'                         : $screen_slg,
  'md'                          : $screen_md,
  '6ls'                         : $screen_6ls,
  '5ls'                         : $screen_5ls,
  'sm'                          : $screen_sm,
  'msm'                         : $screen_msm,
  'xs'                          : $screen_xs,
);


// ============================================================ //
// @ iosアドレスバーバッファ
// ============================================================ //

$ios_adress_bar_buffer          : 60px;

