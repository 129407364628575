@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as fn;

// ============================================================
// container
// ============================================================

.mvWrapper{
  height: 100vh;

  @include mix.max-media(var.$screen-slg){
    height: 50vh;
  }

}

.mvContainer{
  width: 100%;
  height: 100%;
  background-image: url('../images/index/mv.jpg');
  background-position: center bottom;
  background-size: cover;
  margin: 0 auto 50px;

  @include mix.max-media(var.$screen-sm){
  }
}

.topContentsContainer{
  position: relative;
  overflow-x: hidden;
}



// ============================================================
// top section common
// ============================================================


.topSection_ttl {
  color: #231815;
  font-family: 'Lato', sans-serif;
  font-size: 2.8rem;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 36px;
  text-align: center;
}

.topSection_ttl_inner{
  display: block;
  font-size: 1.2rem;
}

// ============================================================
// top seciton unique
// ============================================================

.concept.topSection_concept{
  //FIXME animation実装までは本番環境はpadding: 0 0 10vh;
  padding: 0 0 10vh;
  //padding: 20vh 0 50vh;
  background: #fff;
  margin-bottom: 0;
  @include mix.max-media(var.$screen-sm){
    padding: 10vh 0 20vh;
  }
  .inner{
    min-height: 240px;
    @include mix.max-media(var.$screen-md){
      min-height: auto;
    }
  }
}

//MVにあるとき
.concept.topSection_concept.mv_section{
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform : translate(-50%,-50%);
  background: transparent;
  padding-bottom:0;
  filter: drop-shadow(2px 4px 3px rgba(255, 255, 255, 1));
  @include mix.max-media(var.$screen-slg){
    display: none;
  }
}

//MV下のコンテンツセクションにある時
.concept.topSection_concept.contents_section{
  display: none;

  @include mix.max-media(var.$screen-slg){
    padding-top: 5vh;
    display: block;
  }
}

.works.topSection_works{
  padding:5vh 0;
}

.about.topSection_about{
  padding: 10vh 0;
  margin-bottom: 0;
}

.news.topSection_news{
  margin-bottom: 0;
  padding: 10vh 0;
}

//topのworksセクションのホバーアクション
.works .item ul li a .img{
  overflow: hidden;
}

.works .item ul li a .img img{
  transition:.3s;

  &:hover{
    transform: scale(1.2);
    transition:.3s;
  }
}


footer .company .inner .flex .detail .insta{
  transition:.3s;

  &:hover{
    opacity:.7;
    transition:.3s;
  }
}


// ============================================================
//topページの管理者用バーのz-index変更
// ============================================================

#adminBox, .acms-admin-box{
  position: relative;
  z-index:5000;
}

.news{
  h2{
    @include mix.max-media(var.$screen-sm){
      line-height: 1.4;
    }
    span{
      font-family: var.$font-family_mincho;
      font-weight: 800;
      @include mix.max-media(var.$screen-md){

        font-size: 14px;
      }
    }
  }
}


.news{
  h2{
    span.topSection_ttl_inner{
      font-family: var.$font_family_base;
      font-weight: bold;
    }
  }
}