@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as fn;

.fill{
  &_fff{
    fill: #fff;
  }
  &_333{
    fill: #333;
  }
  &_brandMain{
    fill:#231815;
  }
  &_none{
    fill: none;
  }
}