@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as fn;

// ------------------------------------------------------------
// display visible and hidden
// ------------------------------------------------------------

@each $media, $blakepoint in var.$blakepoints {
  .hidden_#{$media}{
    @media screen and (max-width: #{$blakepoint}) {
      display: none!important;
    }
  }
}

@each $media, $blakepoint in var.$blakepoints {
  .visible_#{$media}{
    display: none!important;
    @media screen and (max-width: #{$blakepoint}) {
      display: block!important;
    }
  }
}

// ------------------------------------------------------------
// display model
// ------------------------------------------------------------

.dis_block{
  display: block;
}

.dis_inlineBlock{
  display: inline-block;
}

.dis_flex{
  display: flex;
}

.dis_grid{
  display: grid;
}

.visually_hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}