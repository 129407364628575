@use "../../variables" as var;
@use "../../mixins" as mix;
@use "../../functions" as fn;

// ============================================================
// form_control
// ============================================================

// # html sample
//
// <div>
//   <label for="exampleFormControlInput1" class="form_label">メールアドレス</label>
//   <input type="email" class="form_control" id="exampleFormControlInput1" placeholder="name@example.com">
// </div>
//

// ## disable
//
// <div>
//   <label for="exampleFormControlInput1" class="form_label">メールアドレス</label>
//   <input type="email" class="form_control" id="exampleFormControlInput1" placeholder="name@example.com" disabled>
// </div>

// ============================================================

.form_label{
  display: inline-block;
  position: relative;
  margin-bottom: var.$field_label_mb;

  &.isRequired:before{
    position: absolute;
    top: 0;
    right: 0;
    transform : translate(150%,0%);
    //
    display: block;
    content: "*";
    color: var.$brand_error;
    font-size: 1.2rem;
  }

}

.form_control{
  display: block;
  width: 100%;
  padding: var.$field_padding_vertical var.$field_padding_side;
  font-size: 1rem;
  font-weight: 400;
  line-height: var.$field_line_height;
  border: solid 1px var.$field_border_color;
  border-radius: var.$field_border_radius;
  color: var.$text_color;
  //
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  &:focus{
    border-color: var.$field_focus_border_color;
    outline: 0;
    box-shadow: 0 0 0 0.25rem var.$field_focus_outline_color;
  }

  &:disabled{
    background-color: var.$field_disabled_bg_color;
    opacity: 1;
  }

  &.isPlaintext{
    border: none;
  }

}

