@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as fn;

// ============================================================
//
// ============================================================

$gapEndValue: 20 !default;

@each $modifier, $size in var.$grid-sizes {
  .grid_cl_#{$modifier} {
    grid-template-columns: repeat(auto-fill, minmax($size, 1fr));
  }
}

@for $i from 0 through $gapEndValue {
  .gap_#{$i*5} {
    gap: 5px * $i;
  }
  @include mix.max-media(var.$screen_lg) {
    .gap_#{$i*5}_lg {
      gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_mlg) {
    .gap_#{$i*5}_mlg {
      gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_slg) {
    .gap_#{$i*5}_slg {
      gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_md) {
    .gap_#{$i*5}_md {
      gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_6ls) {
    .gap_#{$i*5}_6ls {
      gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_5ls) {
    .gap_#{$i*5}_5ls {
      gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_sm) {
    .gap_#{$i*5}_sm {
      gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_msm) {
    .gap_#{$i*5}_msm {
      gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_xs) {
    .gap_#{$i*5}_xs {
      gap: 5px * $i;
    }
  }
}

@for $i from 0 through $gapEndValue {
  .gapx_#{$i*5} {
    column-gap: 5px * $i;
  }
  @include mix.max-media(var.$screen_lg) {
    .gapx_#{$i*5}_lg {
      column-gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_mlg) {
    .gapx_#{$i*5}_mlg {
      column-gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_slg) {
    .gapx_#{$i*5}_slg {
      column-gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_md) {
    .gapx_#{$i*5}_md {
      column-gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_6ls) {
    .gapx_#{$i*5}_6ls {
      column-gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_5ls) {
    .gapx_#{$i*5}_5ls {
      column-gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_sm) {
    .gapx_#{$i*5}_sm {
      column-gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_msm) {
    .gapx_#{$i*5}_msm {
      column-gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_xs) {
    .gapx_#{$i*5}_xs {
      column-gap: 5px * $i;
    }
  }
}

@for $i from 0 through $gapEndValue {
  .gapy_#{$i*5} {
    row-gap: 5px * $i;
  }
  @include mix.max-media(var.$screen_lg) {
    .gapy_#{$i*5}_lg {
      row-gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_mlg) {
    .gapy_#{$i*5}_mlg {
      row-gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_slg) {
    .gapy_#{$i*5}_slg {
      row-gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_md) {
    .gapy_#{$i*5}_md {
      row-gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_6ls) {
    .gapy_#{$i*5}_6ls {
      row-gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_5ls) {
    .gapy_#{$i*5}_5ls {
      row-gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_sm) {
    .gapy_#{$i*5}_sm {
      row-gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_msm) {
    .gapy_#{$i*5}_msm {
      row-gap: 5px * $i;
    }
  }
  @include mix.max-media(var.$screen_xs) {
    .gapy_#{$i*5}_xs {
      row-gap: 5px * $i;
    }
  }
}

