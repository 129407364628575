@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as fn;

//  README
// ------------------------------------------------------------
//  ユーティリティ用のマージンクラスを使用する場合「$utilityMarginClass」を「true」にし
//  必要な各マージンクラスを「marginTop/marginBottom/...」をtrueにする
//  $endValueはpxの最大値を表す
// ------------------------------------------------------------

$utilityMarginClass: true !default;
$step : 5;
$marginTop: true !default;
$marginBottom: true !default;
$marginLeft: false !default;
$marginRight: false !default;
$endValue: 50 !default;

// margin class
// ------------------------------------------------------------

@if $utilityMarginClass {
  @for $i from 0 through $endValue {
    @if $marginTop {
      .mt_#{$i * 5} {
        margin-top: $i * $step + px !important;
      }
      @include mix.max-media(var.$screen_xlg) {
        .mt_#{$i * 5}_xlg {
          margin-top: $i * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_lg) {
        .mt_#{$i * 5}_lg {
          margin-top: $i * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_mlg) {
        .mt_#{$i * 5}_mlg {
          margin-top: $i * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_slg) {
        .mt_#{$i * 5}_slg {
          margin-top: $i * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_md) {
        .mt_#{$i * 5}_md {
          margin-top: $i * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_6ls) {
        .mt_#{$i * 5}_6ls {
          margin-top: $i * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_5ls) {
        .mt_#{$i * 5}_5ls {
          margin-top: $i * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_sm) {
        .mt_#{$i * 5}_sm {
          margin-top: $i * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_msm) {
        .mt_#{$i * 5}_msm {
          margin-top: $i * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_xs) {
        .mt_#{$i * 5}_xs {
          margin-top: $i * $step + px !important;
        }
      }
    }
    @if $marginBottom {
      .mb_#{$i * 5} {
        margin-bottom: $i  * $step+ px !important;
      }
      @include mix.max-media(var.$screen_xlg) {
        .mb_#{$i * 5}_xlg {
          margin-bottom: $i  * $step+ px !important;
        }
      }
      @include mix.max-media(var.$screen_lg) {
        .mb_#{$i * 5}_lg {
          margin-bottom: $i  * $step+ px !important;
        }
      }
      @include mix.max-media(var.$screen_mlg) {
        .mb_#{$i * 5}_mlg {
          margin-bottom: $i  * $step+ px !important;
        }
      }
      @include mix.max-media(var.$screen_slg) {
        .mb_#{$i * 5}_slg {
          margin-bottom: $i  * $step+ px !important;
        }
      }
      @include mix.max-media(var.$screen_md) {
        .mb_#{$i * 5}_md {
          margin-bottom: $i  * $step+ px !important;
        }
      }
      @include mix.max-media(var.$screen_6ls) {
        .mb_#{$i * 5}_6ls {
          margin-bottom: $i  * $step+ px !important;
        }
      }
      @include mix.max-media(var.$screen_5ls) {
        .mb_#{$i * 5}_5ls {
          margin-bottom: $i  * $step+ px !important;
        }
      }
      @include mix.max-media(var.$screen_sm) {
        .mb_#{$i * 5}_sm {
          margin-bottom: $i  * $step+ px !important;
        }
      }
      @include mix.max-media(var.$screen_msm) {
        .mb_#{$i * 5}_msm {
          margin-bottom: $i  * $step+ px !important;
        }
      }
      @include mix.max-media(var.$screen_xs) {
        .mb_#{$i * 5}_xs {
          margin-bottom: $i  * $step+ px !important;
        }
      }
    }
    @if $marginLeft {
      .ml_#{$i * 5} {
        margin-left: $i  * $step + px !important;
      }
      @include mix.max-media(var.$screen_xlg) {
        .ml_#{$i * 5}_xlg {
          margin-left: $i  * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_lg) {
        .ml_#{$i * 5}_lg {
          margin-left: $i  * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_mlg) {
        .ml_#{$i * 5}_mlg {
          margin-left: $i  * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_slg) {
        .ml_#{$i * 5}_xlg {
          margin-left: $i  * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_md) {
        .ml_#{$i * 5}_md {
          margin-left: $i  * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_6ls) {
        .ml_#{$i * 5}_6ls {
          margin-left: $i  * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_5ls) {
        .ml_#{$i * 5}_5ls {
          margin-left: $i  * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_sm) {
        .ml_#{$i * 5}_sm {
          margin-left: $i  * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_msm) {
        .ml_#{$i * 5}_msm {
          margin-left: $i  * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_xs) {
        .ml_#{$i * 5}_xs {
          margin-left: $i  * $step + px !important;
        }
      }
    }
    @if $marginRight {
      .mr_#{$i * 5} {
        margin-right: $i + px !important;
      }
      @include mix.max-media(var.$screen_xlg) {
        .mr_#{$i * 5}_xlg {
          margin-left: $i  * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_lg) {
        .mr_#{$i * 5}_lg {
          margin-left: $i  * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_mlg) {
        .mr_#{$i * 5}_mlg {
          margin-left: $i  * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_slg) {
        .mr_#{$i * 5}_slg {
          margin-left: $i  * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_md) {
        .mr_#{$i * 5}_md {
          margin-left: $i  * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_6ls) {
        .mr_#{$i * 5}_6ls {
          margin-left: $i  * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_5ls) {
        .mr_#{$i * 5}_5ls {
          margin-left: $i  * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_sm) {
        .mr_#{$i * 5}_sm {
          margin-left: $i  * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_msm) {
        .mr_#{$i * 5}_msm {
          margin-left: $i  * $step + px !important;
        }
      }
      @include mix.max-media(var.$screen_xs) {
        .mr_#{$i * 5}_xs {
          margin-left: $i  * $step + px !important;
        }
      }
    }
  }
}

// margin auto class
// ------------------------------------------------------------

// margin-top : auto
// -------------------
.mt_auto {
  margin-top: auto;
}
@include mix.max-media(var.$screen_xlg) {
  .mt_auto--xlg {
    margin-top: auto;
  }
}
@include mix.max-media(var.$screen_lg) {
  .mt_auto--lg {
    margin-top: auto;
  }
}
@include mix.max-media(var.$screen_mlg) {
  .mt_auto--mlg {
    margin-top: auto;
  }
}
@include mix.max-media(var.$screen_slg) {
  .mt_auto--slg {
    margin-top: auto;
  }
}
@include mix.max-media(var.$screen_md) {
  .mt_auto--md {
    margin-top: auto;
  }
}
@include mix.max-media(var.$screen_6ls) {
  .mt_auto--6ls {
    margin-top: auto;
  }
}
@include mix.max-media(var.$screen_5ls) {
  .mt_auto--5ls {
    margin-top: auto;
  }
}
@include mix.max-media(var.$screen_sm) {
  .mt_auto--sm {
    margin-top: auto;
  }
}
@include mix.max-media(var.$screen_msm) {
  .mt_auto--msm {
    margin-top: auto;
  }
}
@include mix.max-media(var.$screen_xs) {
  .mt_auto--xs {
    margin-top: auto;
  }
}

// margin-bottom : auto
// -------------------
.mb_auto {
  margin-bottom: auto;
}
@include mix.max-media(var.$screen_xlg) {
  .mb_auto--xlg {
    margin-bottom: auto;
  }
}
@include mix.max-media(var.$screen_lg) {
  .mb_auto--lg {
    margin-bottom: auto;
  }
}
@include mix.max-media(var.$screen_mlg) {
  .mb_auto--mlg {
    margin-bottom: auto;
  }
}
@include mix.max-media(var.$screen_slg) {
  .mb_auto--slg {
    margin-bottom: auto;
  }
}
@include mix.max-media(var.$screen_md) {
  .mb_auto--md {
    margin-bottom: auto;
  }
}
@include mix.max-media(var.$screen_6ls) {
  .mb_auto--6ls {
    margin-bottom: auto;
  }
}
@include mix.max-media(var.$screen_5ls) {
  .mb_auto--5ls {
    margin-bottom: auto;
  }
}
@include mix.max-media(var.$screen_sm) {
  .mb_auto--sm {
    margin-bottom: auto;
  }
}
@include mix.max-media(var.$screen_msm) {
  .mb_auto--msm {
    margin-bottom: auto;
  }
}
@include mix.max-media(var.$screen_xs) {
  .mb_auto--xs {
    margin-bottom: auto;
  }
}

// margin-left : auto
// -------------------
.ml_auto {
  margin-left: auto;
}
@include mix.max-media(var.$screen_xlg) {
  .ml_auto--xlg {
    margin-left: auto;
  }
}
@include mix.max-media(var.$screen_lg) {
  .ml_auto--lg {
    margin-left: auto;
  }
}
@include mix.max-media(var.$screen_mlg) {
  .ml_auto--mlg {
    margin-left: auto;
  }
}
@include mix.max-media(var.$screen_slg) {
  .ml_auto--slg {
    margin-left: auto;
  }
}
@include mix.max-media(var.$screen_md) {
  .ml_auto--md {
    margin-left: auto;
  }
}
@include mix.max-media(var.$screen_6ls) {
  .ml_auto--6ls {
    margin-left: auto;
  }
}
@include mix.max-media(var.$screen_5ls) {
  .ml_auto--5ls {
    margin-left: auto;
  }
}
@include mix.max-media(var.$screen_sm) {
  .ml_auto--sm {
    margin-left: auto;
  }
}
@include mix.max-media(var.$screen_msm) {
  .ml_auto--msm {
    margin-left: auto;
  }
}
@include mix.max-media(var.$screen_xs) {
  .ml_auto--xs {
    margin-left: auto;
  }
}

// margin-right : auto
// -------------------
.mr_auto {
  margin-right: auto;
}
@include mix.max-media(var.$screen_xlg) {
  .mr_auto--xlg {
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen_lg) {
  .mr_auto--lg {
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen_mlg) {
  .mr_auto--mlg {
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen_slg) {
  .mr_auto--slg {
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen_md) {
  .mr_auto--md {
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen_6ls) {
  .mr_auto--6ls {
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen_5ls) {
  .mr_auto--5ls {
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen_sm) {
  .mr_auto--sm {
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen_msm) {
  .mr_auto--msm {
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen_xs) {
  .mr_auto--xs {
    margin-right: auto;
  }
}

// margin：0 auto;
// -------------------
.ms_auto {
  margin-left: auto;
  margin-right: auto;
}
@include mix.max-media(var.$screen_xlg) {
  .ms_auto--xlg {
    margin-left: auto;
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen_lg) {
  .ms_auto--lg {
    margin-left: auto;
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen_mlg) {
  .ms_auto--mlg {
    margin-left: auto;
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen_slg) {
  .ms_auto--slg {
    margin-left: auto;
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen_md) {
  .ms_auto--md {
    margin-left: auto;
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen_6ls) {
  .ms_auto--6ls {
    margin-left: auto;
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen_5ls) {
  .ms_auto--5ls {
    margin-left: auto;
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen_sm) {
  .ms_auto--sm {
    margin-left: auto;
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen_msm) {
  .ms_auto--msm {
    margin-left: auto;
    margin-right: auto;
  }
}
@include mix.max-media(var.$screen_xs) {
  .ms_auto--xs {
    margin-left: auto;
    margin-right: auto;
  }
}