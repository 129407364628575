@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as fn;


.textUnit{
  max-width:1080px;
  width:86%;
  margin:0 auto 24px;

  @include mix.max-media(var.$screen-lg){
    margin: 0 auto 24px;
  }

  p{
    font-weight: bold;
    text-align:center;
    @include mix.max-media(var.$screen-slg){
      font-size: 1.2rem;
    }
  }
}