@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as fn;


// ============================================================
// 投稿表示用のコンテナ
// ============================================================

.entry_body_Container{
  padding: 40px;

  @include mix.max-media(var.$screen-md){
    padding: 0;
  }
}

