@use "../../variables" as var;
@use "../../mixins" as mix;
@use "../../functions" as fn;

// ============================================================
// form_control
// ============================================================

// # html sample
//
// <label for="exampleFormControlInput1" class="form_label">メールアドレス</label>
// <select class="form_select" id="floatingSelect" aria-label="Floating label select example">
//   <option selected>Open this select menu</option>
//   <option value="1">One</option>
//   <option value="2">Two</option>
//   <option value="3">Three</option>
// </select>
//

// ============================================================

.form_select{
  display: block;
  position: relative;
  width: 100%;
  padding: var.$field_padding_vertical var.$field_padding_side;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var.$text_color;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid var.$field_border_color;
  border-radius: var.$field_border_radius;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");

  &:focus{
    border-color: var.$field_focus_border_color;
    outline: 0;
    box-shadow: 0 0 0 0.25rem var.$field_focus_outline_color;
  }

  &:disabled{
    background-color: var.$field_disabled_bg_color;
    opacity: 1;
  }

}