@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as fn;


// ============================================================
// header
// ============================================================

.tk_header{
  position: fixed;
  z-index: var.$zindex_globalHeader;
  //
  top: 0;
  left: 0;
  right: 0;
  //
  padding: 30px 0 0 0;
  margin-bottom: 0;
  //

  @include mix.max-media(var.$screen-md){
    background: #fff;
    padding-top: 0;
    height: var.$header_H_SP;
  }


  &:before{
    display: block;
    content: '';
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 185px;
  //
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) -26%, rgba(0,0,0,0.004639355742296902) 100%);

    @include mix.max-media(var.$screen-md){
      display: none;
    }
  }

  .icon{
    top: 50px;
    @include mix.max-media(var.$screen-sm){
      top: -40px;
    }
  }
}

.tk_header02{
  @extend .tk_header;
  margin-bottom: 30vw;
}


.tk_header_inner{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  //
  height: 100%;
  padding: 0 4vw;
  //opacity: 0;
  //FIXME animation実装までは本番環境は1 transformはコメントアウトアウト
  opacity: 1;
  //transform : translate(0%,-100%);



  @include mix.max-media(var.$screen-slg){
    flex-flow: column;
    justify-content: center;
    row-gap: 12px;
  }

  @include mix.max-media(var.$screen-md){
    flex-flow: row wrap!important;
    justify-content: start!important;
    padding: 1.2vw 4vw 1.2vw;
  }

  .logo{
    width: 300px;

    @include mix.max-media(var.$screen-md){
      max-width: 200px;
      width: 20vw;
    }

    @include mix.max-media(var.$screen-sm){
      width: 150px;
    }
  }
}

