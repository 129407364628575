// ============================================================
// fadeIn
// ============================================================

@keyframes animation_fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// ============================================================
// fadeOut
// ============================================================


@keyframes animation_fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}