@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as fn;

.works_pager{
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.prev_archiveBtn{
  background: #efefef;
  border-radius: 5rem;


  a{
    display: block;
    padding: 0.3rem 3rem 0 3rem;
    font-weight: 600;
    font-size: 15px;

    @include mix.max-media(var.$screen-md){
      padding: 0 4rem;
    }

    @include mix.max-media(var.$screen-sm){
      font-size:12px;
      padding: .6rem 1rem 0;
    }
  }
}

.pager_text{
  @include mix.max-media(var.$screen-sm){
    font-size: 14px;
  }
}