@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as fn;


// ============================================================
// topニュース表示用ヘッドライン
// ============================================================


.news_headlineList{
  list-style: none;
  margin-bottom: 12vh;
}

.news_headlineItem{
  border-bottom: 1px solid  #333;
  font-family: var.$font-family_base;
  font-weight:bold;
  font-size: 1.6rem;
  padding-bottom: 12px;
  margin-bottom:12px;

  @include mix.max-media(var.$screen-md){
    font-size: 1.1rem;
  }

  &:last-child{
    margin-bottom: 0;
  }

  a{
    color: #333;
    text-decoration: none;
    display: block;
    transition: .3s;

    &:hover{
      transition: .3s;
      opacity: .7;
    }
  }

  time{
    padding-right: .85em;
  }
}