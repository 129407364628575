@use "../../variables" as var;
@use "../../mixins" as mix;
@use "../../functions" as fn;

// ============================================================
// form_radio
// ============================================================

// # html sample
//
//  <div class="mb_40">
//  <p class="form_label">Radio</p>
//  <div>
//    <label class="form_radio" for="radio1">
//      <input type="radio" name="radio" id="radio1">
//      <span>Default radio</span>
//    </label>
//    <label class="form_radio" for="radio2">
//      <input type="radio" name="radio" id="radio2" checked>
//      <span>Default checked radio</span>
//    </label>
//    </div>
//  </div>
//

// ============================================================
input[type=radio], input[type=radio]:checked {
  display: none;
}

.form_radio {
  input[type=radio] + span {
    box-sizing: border-box;
    -webkit-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
    position: relative;
    display: inline-block;
    margin: 0 20px 0 0;
    padding: 0 0 0 2.3em;
    vertical-align: middle;
    cursor: pointer;
  }
  input[type=radio] + span:hover:after {
    border-color: var.$input_active_color;;
  }
}

.form_radio {
  input[type=radio] + span:after {
    @include mix.transitionDelay;

    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    content: '';
    margin-top: -12px;
    width: 20px;
    height: 20px;
    border: 1px solid #bbb;
    border-radius: 50%;
    background: #fff;
  }
  input[type=radio] + span:before {
    z-index: 2;
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
    position: absolute;
    top: 50%;
    left: 6px;
    display: block;
    margin-top: -6px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var.$input_active_color;;
    content: '';
    opacity: 0;
  }
  input[type=radio]:checked + span:before,
  input[type=radio]:checked + span:after {
    opacity: 1;
    border-color: var.$input_active_color;;
  }
}
