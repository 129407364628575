@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as fn;



/*========= スクロールダウンのためのCSS ===============*/

/*====== 9-1-1 縦線が動いてスクロールを促す =======*/

/*スクロールダウン全体の場所*/
.scrolldown1{
  /*描画位置※位置は適宜調整してください*/
  position:absolute;
  z-index: 1001;
  left:50%;
  bottom:10px;
  /*全体の高さ*/
  height:50px;

  @include mix.max-media(var.$screen-slg){
    display:none;
  }
}

/*Scrollテキストの描写*/
.scrolldown1 span{
  /*描画位置*/
  position: absolute;
  left:-19px;
  top: -15px;
  /*テキストの形状*/
  color: #eee;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-shadow: 2px 3px 6px rgba(0, 0, 0, 0.9);
}

/* 線の描写 */
.scrolldown1::after{
  content: "";
  /*描画位置*/
  position: absolute;
  top: 0;
  left: 4px;
  /*線の形状*/
  width: 2px;
  height: 30px;
  background: #eee;
  /*線の動き1.4秒かけて動く。永遠にループ*/
  animation: pathmove 1.4s ease-in-out infinite;
  opacity:0;
}

/*高さ・位置・透過が変化して線が上から下に動く*/
@keyframes pathmove{
  0%{
    height:0;
    top:0;
    opacity: 0;
  }
  30%{
    height:30px;
    opacity: 1;
  }
  100%{
    height:0;
    top:50px;
    opacity: 0;
  }
}