@use "../../variables" as var;
@use "../../mixins" as mix;
@use "../../functions" as fn;

// ============================================================
// form_checkbox
// ============================================================

// # html sample
//
//  <p class="form_label">Checkbox</p>
//  <div>
//    <label class="form_checkbox" for="check1">
//      <input type="checkbox" name="check" id="check1">
//      <span>Default radio</span>
//    </label>
//    <label class="form_checkbox" for="check2">
//      <input type="checkbox" name="check" id="check2" checked>
//      <span>Default checked radio</span>
//    </label>
//  </div>
//

// ============================================================

input[type=checkbox], input[type=checkbox]:checked {
  display: none!important;
}

.form_checkbox {
  input[type=checkbox] + span {
    box-sizing: border-box;
    -webkit-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
    position: relative;
    display: inline-block;
    margin: 0 20px 0 0;
    padding: 0 0 0 2.3em;
    vertical-align: middle;
    cursor: pointer;
  }
  input[type=checkbox] + span:hover:after {
    border-color: var.$input_active_color;
  }
}

.form_checkbox {
  input[type=checkbox] + span:after {
    @include mix.transitionDelay;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    content: '';
    margin-top: -12px;
    width: 20px;
    height: 20px;
    border: 1px solid #bbb;
    border-radius: 3px;
    background: #fff;
  }
  input[type=checkbox] + span:before {
    z-index: 2;
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
    transform: rotate(-45deg);
    position: absolute;
    top: 50%;
    left: 5px;
    display: block;
    margin-top: -7px;
    width: 10px;
    height: 6px;
    border-bottom: solid 2px var.$input_active_color;
    border-left: solid 2px var.$input_active_color;
    content: '';
    opacity: 0;
  }
  input[type=checkbox]:checked {
    display: block;
    background: #000;
  }
  input[type=checkbox]:checked + span:before,
  input[type=checkbox]:checked + span:after {
    opacity: 1;
    border-color: var.$input_active_color;
  }
}