@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as fn;

[class*=column-image] img, [class*=column-media] img, [class*=column-eximage] img{
  width: 100%;
  height: auto;
  margin: 0 auto;
  object-fit: cover;
}


[class*=acms-col]{
  padding: 0;

  @include mix.max-media(var.$screen-md){
    padding: 0;
  }
}