@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as fn;


// ============================================================
// 投稿タイトル用見出し
// ============================================================

.entry_titleHeading{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: end;
  //
  border-bottom: 1px solid #000;
  font-size: 24px;
  padding-bottom: 4px;

  @include mix.max-media(var.$screen-md){
    font-size: 16px;

  }
}

.entry_categoryHeading{
  font-size: .85em;
  padding-bottom: 4px;

  @include mix.max-media(var.$screen-md){
    font-size: .8em;
  }
}

.entry_worksStyleLead{
  font-weight: bold;
  margin-bottom: 36px;
  
  @include mix.max-media(var.$screen-md){
    font-size: 1.4rem;
    margin-bottom: 18px;
  }
}