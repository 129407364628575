@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as fn;

.container{
  padding-top: 20vw;
}

.container h1{
  top:24vw;
}

.container.contact_container h1{
  top:25.3vw;

  @include mix.max-media(var.$screen-md){
    top: 0;
  }
}

.news .news_inner{
  max-width: 800px!important;
}