@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as fn;

// ============================================================
// gnav
// ============================================================

.gnav{

  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  column-gap: 32px;

  @include mix.max-media(var.$screen-lg){
    column-gap: 20px;
  }

  @include mix.max-media(var.$screen-slg){
    column-gap: 12px;
  }

  @include mix.max-media(var.$screen-md){
    display: none;
  }

}

.icon{
  display: flex;
  flex-flow: row wrap;
  column-gap: 8px;

  @include mix.max-media(var.$screen-md){
    justify-content: center;
    align-items: center;
    margin-bottom:3.3vw;
  }

  li{
    @include mix.max-media(var.$screen-md){
      width: 33px!important;
      height: 40px!important;
      margin:0!important;
    }
  }

  a{
    display: block;
    transition: .3s;

    &:hover {
      transition: .3s;
      opacity: 0.7;
    }
  }

  a img{
    width: 100%;
    vertical-align: middle;
  }
}

.gnav_list{
  display: flex;
  justify-content: center;
  column-gap: 32px;

  @include mix.max-media(var.$screen-lg){
    column-gap: 20px;
  }

  @include mix.max-media(var.$screen-mlg){
    column-gap: 12px;
  }

}

.gnav_listItem{
  font-family: Lato, sans-serif;
  font-weight: bold;
  align-self: center;
}

