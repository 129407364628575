@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as fn;

.dis_flex{
  .name,.cat,.style{
    display:inline-block;
    font-size:1.6rem;
    @include mix.max-media(var.$screen-sm){
      font-size:1.2rem;
    }
  }
}