@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as fn;

.btn_group_left{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: -8px;
  [class*=btn]{
    margin: 0 3px 8px 3px;
  }
}

.btn_group_center{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: -8px;
  [class*=btn]{
    margin: 0 3px 8px 3px;
  }
}
